.cmn_table_wrap {
  .ant-table-wrapper {
    .ant-table {
      padding: 0;
      border-radius: 10px;
      overflow: hidden;

      @include themify($themes) {
        background: themed('whiteBg');
        border: 1px solid themed('borderColor');
      }

      .ant-table-container {
        table {
          thead {
            position: sticky;
            top: 0;
            z-index: 100;
            background-color: transparent;

            th {
              @include themify($themes) {
                background: themed('tableHead');
                color: themed('textDark');
                border-bottom: 1px solid themed('borderColor');
              }

              &::before {
                display: none;
              }

              &:first-child {
                border-start-start-radius: 0;
              }

              &:last-child {
                border-start-end-radius: 0;
              }
            }
          }

          tbody {
            tr {
              td {
                @include themify($themes) {
                  background: themed('whiteBg');
                  color: themed('textDark');
                  border-bottom: 1px solid themed('borderColor');
                }

                .concat_text {
                  width: 120px;
                  white-space: wrap;
                }
              }

              &:hover {
                td {
                  @include themify($themes) {
                    background: themed('whiteBg');
                    color: themed('textDark');
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// .lead_managment_main_wrap
//   .ant-form-item
//   .ant-form-item-row
//   .ant-form-item-control
//   .ant-input,
// .lead_managment_main_wrap .ant-form-item .ant-picker {
//   @include themify($themes) {
//     border: 1px solid themed('bgBoxBorderColor');
//   }
// }

.add_lead_select {
  .custom_select {
    .ant-select {
      height: auto;
      max-width: 100%;
    }
  }
}

.all_lead_table_wrap {
  .custom_select {
    .ant-pagination-options {
      display: none;
    }
  }
}

.date_column {
  white-space: normal !important;
  word-wrap: break-word !important;
  text-align: center !important;
}

@media (max-width: 1681px) {

  .cmn_table_wrap .ant-table-wrapper .ant-table .ant-table-container table tbody tr td,
  .cmn_table_wrap .ant-table-wrapper .ant-table .ant-table-container table thead tr th {
    padding: 10px 15px;
    font-size: 13px;
  }
}

@media (max-width: 575px) {

  .cmn_table_wrap .ant-table-wrapper .ant-table .ant-table-tbody .ant-table-cell,
  .cmn_table_wrap .ant-table-wrapper .ant-table .ant-table-thead .ant-table-cell {
    padding: 10px;
    font-size: 13px;
  }
}