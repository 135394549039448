/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-input[aria-invalid='true'] {
  @include themify($themes) {
    border: 1px solid #ff4d4f !important;
  }
}

.custom_input {
  .ant-form-item-row {
    flex-direction: column;

    .ant-form-item-label {
      label {
        font-size: 16px;
        font-weight: 400;
        display: block;
        text-align: start;
      }
    }
  }
}

.ant-form-item {
  .ant-form-item-row {
    .ant-form-item-label {
      label {
        font-size: 16px;
        font-weight: 400;

        @include themify($themes) {
          color: themed('textDark');
        }

        &:before {
          position: absolute;
          right: 0;
        }
      }
    }

    .ant-form-item-control {
      .ant-input {
        padding: 12px 20px;
        font-size: 14px;
        font-weight: 400;
        border-radius: 10px;

        @include themify($themes) {
          border: 1px solid themed('borderColor');
          color: themed('textDark');
          background: themed('bgWhite');
        }

        &:focus {
          @include themify($themes) {
            border-color: themed('primary');
          }

          box-shadow: none;
        }
      }

      .ant-input-password {
        padding: 0;
        background: transparent;

        @include themify($themes) {
          border: 1px solid themed('borderColor');
        }

        border-radius: 10px;

        .ant-input {
          border: 0;
          padding: 17px 20px;
        }

        &.ant-input-affix-wrapper-focused {
          @include themify($themes) {
            border-color: themed('primary');
          }

          box-shadow: none;
        }

        .ant-input-suffix {
          position: absolute;
          right: 12px;
          top: 16px;

          .anticon {
            font-size: 20px;

            @include themify($themes) {
              color: themed('textDark');
            }
          }
        }
      }
    }
  }
}

.ant-checkbox-wrapper {
  span {
    @include themify($themes) {
      color: themed('textDark');
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      width: 24px;
      height: 24px;

      @include themify($themes) {
        border-color: themed('borderColor');
        background: themed('bgWhite');
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        @include themify($themes) {
          background-color: themed('primary');
          border-color: themed('primary');
        }

        &:after {
          width: 7px;
          height: 14px;
          top: 42%;
        }
      }
    }

    &:hover {
      .ant-checkbox-inner {
        @include themify($themes) {
          border-color: themed('primary');
        }
      }
    }

    &:after {
      border: 0 !important;
    }
  }

  &:hover {
    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          @include themify($themes) {
            border-color: themed('primary');
            background-color: themed('primary');
          }
        }
      }

      // &:after{
      //     border-color: transparent !important;
      // }
    }

    .ant-checkbox-inner {
      @include themify($themes) {
        border-color: themed('primary');
      }
    }
  }
}

.ant-input::-webkit-input-placeholder {
  color: $textLight;
}

.ant-input:-ms-input-placeholder {
  color: $textLight;
}

.ant-input::placeholder {
  color: $textLight;
}

@mixin CustomButton {
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 7px;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;

  &:hover,
  &:focus {
    box-shadow: none !important;
    outline: 0 !important;
  }
}

.btn_primary {
  @include CustomButton;
  color: $white;

  img {
    width: 14px;
    height: 14px;
  }

  @include themify($themes) {
    background: themed('primary') !important;
    border: 1px solid themed('primary') !important;
  }

  &:hover,
  &:focus {
    background: transparent !important;

    @include themify($themes) {
      border-color: themed('primary') !important;
      color: themed('primary') !important;
    }
  }

  &:disabled {
    color: $white;

    &:hover {
      color: $white !important;

      @include themify($themes) {
        background: themed('primary');
        border: 1px solid themed('primary');
      }
    }
  }
}

.btn_gray {
  @include CustomButton;
  color: $textLight;

  @include themify($themes) {
    background: themed('bgLight') !important;
    border: 1px solid themed('bgLight') !important;
  }

  &:hover,
  &:focus {
    color: $whiteText !important;

    @include themify($themes) {
      background: themed('primary') !important;
      border-color: themed('primary') !important;
    }
  }

  &:disabled {
    color: $textLight;

    &:hover {
      color: $textLight !important;

      @include themify($themes) {
        background: themed('bgLight');
        border: 1px solid themed('bgLight') !important;
      }
    }
  }
}

.btn_border {
  @include CustomButton;
  background: transparent;

  @include themify($themes) {
    color: themed('primary');
    border: 1px solid themed('primary');
  }

  &:hover,
  &:focus {
    color: $whiteText !important;

    @include themify($themes) {
      background: themed('primary');
      border-color: themed('primary') !important;
    }
  }
}

.Select_style_one {
  .ant-select {
    .ant-select-selector {
      height: 58px;
      padding: 0 20px;

      @include themify($themes) {
        border: 1px solid themed('borderColor');
      }

      .ant-select-selection-placeholder {
        line-height: 58px;
        font-size: 14px;
        font-weight: 400;
        color: $textLight;
      }

      .ant-select-selection-item {
        line-height: 58px;
      }
    }

    &:hover {
      .ant-select-selector {
        @include themify($themes) {
          border-color: themed('primary') !important;
        }
      }
    }

    &.ant-select-open {
      .ant-select-selector {
        box-shadow: none !important;

        @include themify($themes) {
          border-color: themed('primary') !important;
        }
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: none !important;

        @include themify($themes) {
          border-color: themed('primary') !important;
        }
      }
    }

    .ant-select-arrow {
      right: 38px;
      top: 22px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        background: url(../../Img/select-arrow.svg);
      }

      .anticon-down {
        svg {
          display: none;
        }
      }
    }
  }

  &.phone_number_select {
    .ant-select {
      .ant-select-selector {
        padding: 0 10px !important;
      }
    }
  }
}

.ant-select-dropdown {
  padding: 0;
  border-radius: 10px;
  box-shadow: none;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);

  .rc-virtual-list {
    .rc-virtual-list-holder {
      .rc-virtual-list-holder-inner {
        .ant-select-item {
          font-size: 14px;
          padding: 10px 12px;
          border-radius: 0;

          &.ant-select-item-option-selected {
            font-weight: 500;
          }
        }
      }
    }

    .rc-virtual-list-scrollbar {
      width: 3px !important;

      .rc-virtual-list-scrollbar-thumb {
        background: #e5ebf0 !important;
      }
    }
  }
}

.main_wrapper {
  @include themify($themes) {
    background: themed('whiteBg');
  }

  .inner_wrapper {
    display: flex;

    .sidebar_inner {
      width: 300px;
      height: 100dvh;
      transition: 0.5s ease;

      @include themify($themes) {
        border-right: 1px solid themed('primary');
      }

      @media (max-width: 1681px) {
        width: 250px;
      }

      @media (max-width: 1441px) {
        width: 230px;
      }
    }

    .content_wrapper {
      width: calc(100% - 300px);
      height: 100dvh;
      transition: 0.5s ease;

      @media (max-width: 1681px) {
        width: calc(100% - 250px);
      }

      @media (max-width: 1441px) {
        width: calc(100% - 230px);
      }
    }
  }

  &.sidebar_collapse {
    .sidebar_inner {
      width: 81px;
    }

    .content_wrapper {
      width: calc(100% - 81px);
    }
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      @include themify($themes) {
        background: themed('primary');
      }
    }
  }

  .ant-tooltip-arrow {
    &:before {
      @include themify($themes) {
        background: themed('primary');
      }
    }
  }
}

.btn_transparent {
  border: 0;
  padding: 0;
  background: transparent;
}

.bedge {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 12px;
  border-radius: 7px;

  &.bedge_active {
    @include themify($themes) {
      color: themed('greenBg');
      background: themed('greenLight');
    }
  }

  &.bedge_inactive {
    @include themify($themes) {
      color: themed('grayBg');
      background: themed('grayLight');
    }
  }
}

.ant-tabs {
  .ant-tabs-nav {
    &:before {
      @include themify($themes) {
        border-color: themed('borderColor');
      }
    }

    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 37px;
            background: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              #96a0b5 49.48%,
              rgba(255, 255, 255, 0) 100%
            );
            border-radius: 7px;
          }

          &:first-child {
            &::before {
              display: none;
            }
          }

          .ant-tabs-tab-btn {
            font-size: 18px;
            font-weight: 400;

            @include themify($themes) {
              color: themed('textDark');
            }
          }

          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              @include themify($themes) {
                color: themed('primary');
              }

              text-shadow: none;
            }
          }
        }

        .ant-tabs-ink-bar {
          @include themify($themes) {
            background: themed('primary');
          }
        }
      }
    }
  }
}

.react-tel-input {
  .form-control {
    height: auto;
    padding: 11px 20px 11px 60px;

    @include themify($themes) {
      border: 1px solid themed('borderColor');
      color: themed('textDark');
    }

    background: transparent;
    border-radius: 10px;
    width: 100%;

    &:focus {
      box-shadow: none;

      @include themify($themes) {
        border-color: themed('primary');
      }
    }
  }

  .flag-dropdown {
    background-color: transparent;
    border: 0;
    border-radius: 10px 0 0 10px;

    &.open {
      background: transparent;

      .selected-flag {
        background: transparent;
      }
    }

    .selected-flag {
      width: 58px;
      padding: 0 0 0 18px;
      border-radius: 3px 0 0 3px;

      &:hover,
      &:focus {
        background: transparent;
      }

      .flag {
        .arrow {
          margin-top: -3px;
          left: 23px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 6px solid #555;

          &.up {
            border-bottom: 0;
          }
        }
      }
    }

    .country-list {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);

      @include themify($themes) {
        background: themed('whiteBg');
        border: 1px solid themed('borderColor');
      }

      .country {
        @include themify($themes) {
          color: themed('textDark');
        }

        font-size: 13px;

        .dial-code {
          color: $textLight;
        }

        &.highlight,
        &:hover {
          @include themify($themes) {
            background: themed('primaryLight');
            color: themed('textPrimary');
          }

          .dial-code {
            @include themify($themes) {
              color: themed('textDark');
            }
          }
        }
      }

      border-radius: 10px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.validation_error .react-tel-input .form-control {
  border: 1px solid #ff4d4f;
}

.form_process_wrapper .react-tel-input .form-control {
  padding: 10px 20px 11px 60px;
}

.ant-form-item {
  .ant-picker {
    padding: 11px 12px;
    border-radius: 10px;

    @include themify($themes) {
      border-color: themed('borderColor');
    }

    width: 100%;
    background: transparent;

    &.ant-picker-focused {
      box-shadow: none !important;
      border-color: #d3b474;
    }

    &.ant-picker-status-error {
      background: transparent;
      border: 1px solid #ff4d4f;

      &:hover {
        background-color: transparent !important;
      }
    }

    .ant-picker-input {
      input {
        @include themify($themes) {
          color: themed('textDark');
          // color: #555f70;
        }

        &::placeholder {
          color: #949aa3;
        }
      }

      .ant-picker-suffix {
        .anticon-calendar {
          position: relative;
          width: 24px;
          height: 24px;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 24px;
            height: 24px;
            background: url(../../Img/calender.svg);
            background-repeat: no-repeat;

            @include themify($themes) {
              filter: themed('brightnessBW');
            }
          }

          svg {
            display: none;
          }
        }

        .anticon-clock-circle {
          @include themify($themes) {
            color: themed('textDark');
            font-size: 18px;
          }
        }
      }

      .ant-picker-clear {
        background: transparent;

        @include themify($themes) {
          color: themed('textDark');
        }

        &:hover {
          opacity: 0.8;

          @include themify($themes) {
            color: themed('textDark');
          }
        }
      }

      .ant-picker-clear {
        right: 26px;
      }
    }

    .ant-picker-active-bar {
      background: #d3b474;
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    border-radius: 15px;
    padding: 20px;

    .ant-picker-date-panel {
      .ant-picker-header {
        padding: 0 12px;
        border: 0;
        margin: 0;

        .ant-picker-header-super-prev-btn,
        .ant-picker-header-super-next-btn {
          display: none;
        }

        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn {
          line-height: 30px;
        }

        .ant-picker-header-view {
          order: -1;
          text-align: left;
          line-height: 30px;

          .ant-picker-month-btn,
          .ant-picker-year-btn {
            &:hover {
              color: #d3b474;
            }
          }
        }
      }

      .ant-picker-body {
        padding: 0;

        .ant-picker-content {
          width: 292px;

          tbody {
            tr {
              td {
                &.ant-picker-cell-today {
                  .ant-picker-cell-inner {
                    &:before {
                      border-color: #d3b474;
                    }
                  }
                }

                &.ant-picker-cell-selected {
                  .ant-picker-cell-inner {
                    background: #d3b474 !important;
                    border-radius: 50% !important;
                  }
                }

                &.ant-picker-cell-range-end {
                  &::before {
                    background: #d3b474 !important;
                    inset-inline-end: 0 !important;
                  }

                  .ant-picker-cell-inner {
                    background: #d3b474 !important;
                  }
                }

                &.ant-picker-cell-range-start {
                  &::before {
                    background: #d3b474 !important;
                    inset-inline-start: 0 !important;
                  }

                  .ant-picker-cell-inner {
                    background: #d3b474 !important;
                  }
                }
              }
            }
          }
        }
      }

      + .ant-picker-footer {
        display: none;
      }
    }

    .ant-picker-year-panel,
    .ant-picker-month-panel,
    .ant-picker-decade-panel {
      .ant-picker-header {
        .ant-picker-header-view {
          @include themify($themes) {
            color: themed('textDark') !important;
          }

          .ant-picker-year-btn,
          .ant-picker-decade-btn {
            &:hover {
              color: #d3b474;
            }
          }
        }
      }

      .ant-picker-body {
        table {
          tbody {
            tr {
              td {
                &.ant-picker-cell-selected {
                  .ant-picker-cell-inner {
                    background: #d3b474;
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-picker-footer {
      .ant-picker-ranges {
        .ant-picker-now {
          .ant-picker-now-btn {
            color: #d3b474;
          }
        }

        .ant-picker-ok {
          .ant-btn-primary {
            background: #d3b474;
          }
        }
      }
    }
  }
}

.custom_select {
  .ant-select {
    height: auto;
    max-width: 100%;

    .ant-select-selector {
      height: auto;
      padding: 8px 12px;

      @include themify($themes) {
        border: 1px solid themed('borderColor') !important;
      }

      background: transparent;
      border-radius: 10px;

      &:hover {
        @include themify($themes) {
          border-color: themed('borderColor');
        }
      }

      .ant-select-selection-placeholder {
        color: $textLight;
      }

      .ant-select-selection-item {
        @include themify($themes) {
          color: themed('textDark');
        }
      }
    }

    .ant-select-arrow {
      right: 38px;
      top: 22px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: -4px;
        width: 24px;
        height: 24px;
        background: url(../../Img/select-arrow.svg);

        @include themify($themes) {
          filter: themed('brightnessBW');
        }
      }

      .anticon-down {
        svg {
          display: none;
        }
      }
    }

    &.ant-select-open {
      .ant-select-selector {
        border-radius: 10px;
      }

      .ant-select-arrow {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }

    &:hover {
      .ant-select-selector {
        @include themify($themes) {
          border-color: themed('borderColor') !important;
        }
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border-color: #d3b474 !important;
        box-shadow: none !important;
      }

      &:hover {
        .ant-select-selector {
          @include themify($themes) {
            border-color: #d3b474 !important;
          }
        }
      }
    }

    &.ant-select-status-error {
      .ant-select-selector {
        border: 1px solid #ff4d4f !important;
      }
    }
  }
}

.page_header_right .custom_select .ant-select .ant-select-selector {
  padding-right: 25px;
}

.page_header_right .custom_select .ant-select .ant-select-arrow:after {
  left: 10px;
}

.custom_select .ant-select .ant-select-arrow:after .upload_Wrapper {
  .photo_upload_inner {
    @include themify($themes) {
      border: 1px solid themed('borderColor');
    }

    border-radius: 10px;
    padding: 10px;

    .uploaded_img {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      height: 130px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background: #f7f7f7;
      }

      .btn_transparent {
        position: absolute;
        right: 5px;
        top: 2px;
        width: auto;
        height: auto;
      }
    }
  }

  .ant-upload-wrapper {
    .upload_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include themify($themes) {
        border: 1px dashed themed('borderColor');
      }

      border-radius: 10px;
      padding: 22px 10px;

      img {
        width: 20px;
      }

      p {
        font-size: 11px;
        font-weight: 400;
        margin: 7px 0;
        line-height: 14px;
      }

      span {
        color: #007aff;
      }
    }
  }
}

.ant-picker-dropdown
  .ant-picker-date-panel
  .ant-picker-content
  tbody
  tr
  td.ant-picker-cell {
  opacity: 0.4;

  &.ant-picker-cell-in-view {
    opacity: 1;

    &.ant-picker-cell-disabled {
      opacity: 0.5;
    }
  }
}

.cmn_table_wrap {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-content {
        overflow: auto;
        height: auto;
        max-height: calc(100vh - 310px);

        table {
          white-space: nowrap;
        }
      }
    }
  }
}

.checkbox_group_wrap {
  > label {
    color: #96a0b5;
    font-size: 16px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0 10px 10px;
    }
  }
}

body .ant-spin-nested-loading {
  .ant-spin {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 73%);
    color: #d3b474;
    z-index: 999;

    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: #d3b474;
      }
    }
  }
}

.ant-pagination {
  margin-top: 10px;
  text-align: center;
  // text-align: end;
  // text-align: left;

  li {
    &:hover {
      background: #d3b474 !important;
      border: 1px solid #d3b474;
      color: #fff !important;
    }

    &.ant-pagination-item-active {
      background: #d3b474;
      border: 1px solid #d3b474;
      color: #fff;
    }

    .ant-pagination-item-link {
      display: flex !important;
      align-items: center;
      justify-content: center;
    }

    &.ant-pagination-options {
      .ant-select {
        height: auto;
        position: relative;

        .ant-select-selector {
          padding: 3px 10px 3px 15px;
        }

        .ant-select-arrow {
          right: 6px !important;
          top: 19px !important;
          opacity: 1 !important;
          display: flex;
          align-items: center;
          height: 20px !important;
          width: 20px !important;
        }
      }

      &:hover {
        background: transparent !important;
        border: transparent !important;
      }
    }

    &.ant-pagination-jump-next {
      .anticon-double-right {
        display: none;
      }

      .ant-pagination-item-ellipsis {
        opacity: 1 !important;
      }
    }
  }

  .ant-pagination-jump-next-custom-icon,
  .ant-pagination-jump-prev-custom-icon {
    .ant-pagination-item-link {
      display: block !important;

      .ant-pagination-item-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon-double-right,
        .anticon-double-left {
          color: #000;
          line-height: 30px;
        }

        .ant-pagination-item-ellipsis {
          color: inherit !important;
        }
      }
    }
  }
}

.ant-pagination {
  .ant-pagination-jump-next-custom-icon,
  .ant-pagination-jump-prev-custom-icon {
    .ant-pagination-item-link {
      display: block !important;

      .ant-pagination-item-container {
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon-double-right,
        .anticon-double-left {
          color: #000;
          line-height: 30px;
        }
      }
    }
  }
}

.btn_transparent {
  background: transparent !important;
  border: none;
}

.reset_second_Wrapper {
  .login_form_Inner {
    p {
      font-size: 16px;
    }
  }
}

.radio_wrapper {
  padding: 12px 18px;
  border-radius: 10px;
  margin-bottom: 24px;

  @include themify($themes) {
    border: 1px solid themed('borderColor');
  }

  label {
    display: block;
    margin-bottom: 15px;

    @include themify($themes) {
      color: themed('grayBg');
    }
  }

  .ant-radio-group {
    display: flex;
    gap: 10px;

    .ant-radio-wrapper {
      display: flex;
      align-items: center;

      .ant-radio {
        .ant-radio-inner {
          height: 20px;
          width: 20px;
          border-radius: 4px;
          background: transparent;
          position: relative;

          @include themify($themes) {
            border: 1px solid themed('borderColor');
          }

          &::after {
            display: none;
          }

          &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 4px;
            background-image: url(.././../Img/true-white.svg);
            width: 10px;
            height: 10px;
            background-repeat: no-repeat;
            opacity: 0;
            visibility: hidden;
          }
        }

        &.ant-radio-checked {
          .ant-radio-inner {
            @include themify($themes) {
              background: themed('primary');
            }

            &::before {
              opacity: 1;
              visibility: visible;
            }
          }

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.note_wrap {
  padding: 10px;
  border-radius: 8px;

  @include themify($themes) {
    background: themed('bgLight');
    color: themed('grayBg');
  }
}

.not_found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;

  h2 {
    color: #000;
    margin-top: 30px;
  }
}

.min_auto {
  min-width: auto !important;
}

.round_radio_wrapper {
  .ant-radio-group {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .ant-radio-wrapper {
      flex-wrap: nowrap;
      white-space: nowrap;
      align-items: center;

      @include themify($themes) {
        color: themed('textDark');
      }

      .ant-radio {
        .ant-radio-inner {
          background: transparent;

          @include themify($themes) {
            border-color: themed('borderColor');
          }
        }

        &.ant-radio-checked {
          .ant-radio-inner {
            @include themify($themes) {
              background-color: themed('primary');
              border-color: themed('primary');
            }

            &::after {
              transform: scale(0.5);
            }
          }
        }
      }
    }
  }

  &.border_left {
    padding-left: 20px;
    margin-left: 10px;

    @include themify($themes) {
      border-left: 1px solid themed('borderColor');
    }
  }
}

.bedge {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  padding: 4px 12px;
  border-radius: 7px;

  &.bedge_active {
    @include themify($themes) {
      color: themed('greenBg');
      background: themed('greenLight');
    }
  }

  &.bedge_inactive {
    color: #fff !important;
    background: #ff4d4fbf !important;
  }
}

.ant-popover {
  .ant-popover-content {
    .ant-popover-inner {
      padding: 14px 24px;
      .delete_popover_wrap {
        .ant-btn {
          min-width: auto;
          padding: 6px 20px;
        }
      }
    }
  }
}

.ant-popconfirm {
  .ant-popconfirm-message {
    .ant-popconfirm-message-icon {
      display: none;
    }
    .ant-popconfirm-title {
      font-size: 18px;
    }
  }
  .ant-popconfirm-buttons {
    .ant-btn {
      padding: 5px 20px;
      height: auto;
      font-size: 14px;
      line-height: 18px;
      border-color: transparent !important;
      &.ant-btn-default {
        background: #96a0b5;
        color: #ffffff;
        &:hover {
          background: #d3b474 !important;
        }
        &:focus {
          background: #d3b474 !important;
        }
      }
      &.ant-btn-primary {
        background: #d3b474;
        border: 1px solid #d3b474;
        color: #fff;

        &:hover {
          border-color: #d3b474 !important;
          color: #d3b474 !important;
          background: transparent;
        }

        &:focus {
          border-color: #d3b474 !important;
          color: #d3b474 !important;
          background: transparent;
        }
      }
    }
  }
}

@media (max-width: 1681px) {
  .cmn_table_wrap .ant-table-wrapper .ant-table-container .ant-table-content {
    overflow: auto;
    height: auto;
    max-height: calc(100vh - 285px);
  }

  .bedge {
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 5px;
  }

  .custom_select .ant-select .ant-select-selector {
    padding: 7px 12px;
    font-size: 13px;
  }

  .ant-form-item
    .ant-picker
    .ant-picker-input
    .ant-picker-suffix
    .anticon-calendar:after {
    background-size: 22px;
  }

  @mixin CustomButton {
    padding: 9px 12px;
    font-size: 14px;
    min-width: auto;
  }

  .btn_primary {
    @include CustomButton;
  }
}

@media (max-width: 1441px) {
  .ant-form-item .ant-picker {
    padding: 8px 12px;
  }

  .custom_select .ant-select .ant-select-selector {
    padding: 5px 12px;
  }

  .btn_primary {
    padding: 8px 15px;
  }

  .cmn_table_wrap .ant-table-wrapper .ant-table-container .ant-table-content {
    max-height: calc(100vh - 280px);
  }

  .custom_select .ant-select .ant-select-arrow {
    right: 30px;
    top: 20px;
  }

  .page_header_right .custom_select .ant-select .ant-select-arrow:after {
    left: 2px;
  }

  @mixin CustomButton {
    font-size: 13px;
  }

  .btn_primary {
    @include CustomButton;
  }

  .ant-form-item
    .ant-picker
    .ant-picker-input
    .ant-picker-suffix
    .anticon-calendar:after {
    background-size: 20px;
    left: 7px;
    top: 2px;
  }
}

@media (max-width: 1279px) {
  .main_wrapper .content_wrapper {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .search_bar_mobile {
    padding: 20px;
  }

  .page_header_right .search_bar_mobile {
    padding: 0;
  }

  .cmn_table_wrap .ant-table-wrapper .ant-table-container .ant-table-content {
    overflow: auto;
    height: auto;
    max-height: 500px;
  }
}

@media (max-width: 575px) {
  .search_bar_mobile {
    display: none !important;
  }

  .ant-form-item .ant-form-item-row .ant-form-item-label label {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .ant-form-item .ant-form-item-row .ant-form-item-control .ant-input {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  .ant-picker-dropdown .ant-picker-panel-container {
    padding: 10px;
  }

  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-date-panel {
    width: 100%;
  }

  .ant-picker-dropdown
    .ant-picker-panel-container
    .ant-picker-date-panel
    .ant-picker-body
    .ant-picker-content {
    width: 250px;
  }

  .ant-picker-dropdown .ant-picker-date-panel .ant-picker-content {
    font-size: 12px;
  }

  .ant-pagination li.ant-pagination-item,
  .ant-pagination li.ant-pagination-prev,
  .ant-pagination li.ant-pagination-next {
    min-width: 25px;
    height: 25px;
    line-height: 25px;
    margin-right: 5px;
  }
}
