.page_main_content {
  padding: 30px;
  height: calc(100% - 85px);

  .page_inner_content {
    @include themify($themes) {
      border: 1px solid themed('borderColor');
    }

    border-radius: 10px;
    padding: 20px;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &.lead_managment_main_wrap {
    overflow: auto;
    .page_inner_content {
      @include themify($themes) {
        background: themed('contentBg');
      }
    }
  }
}

.page_header {
  padding: 0 0 20px;
  margin-bottom: 15px;

  @include themify($themes) {
    border-bottom: 1px solid themed('borderColor');
  }
}

.page_header_right {
  ul {
    display: flex;
    justify-content: end;
    // align-items: center;
    flex-wrap: wrap;
    li {
      margin: 0 5px;
    }
  }
}

.all_lead_wrap {
  .page_header_right {
    ul {
      flex-wrap: nowrap;
      li {
        // .custom_select {
        //   min-width: 150px;
        // }
      }
    }
  }
}

.user_main_wrap {
  height: calc(100vh - 320px);
  overflow: hidden auto;

  .user_box {
    padding: 15px 0;
    position: relative;

    @include themify($themes) {
      border-bottom: 1px solid themed('borderColor');
    }

    .user_img {
      width: 60px;
      height: 60px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      .user_name_letter {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e5ebf0;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 600;
      }

      @media (max-width: 575px) {
        width: 50px;
        height: 50px;
      }
      @media (max-width: 480px) {
        width: 40px;
        height: 40px;
      }
    }

    .user_text {
      padding-left: 20px;
      width: calc(100% - 60px);

      .bedge {
        display: none;

        @media (max-width: 767px) {
          display: inline-block;

          @media (max-width: 575px) {
            font-size: 14px;
            padding: 4px 12px;

            @media (max-width: 480px) {
              font-size: 10px;
              padding: 2px 8px;
            }
          }
        }
      }

      h3 {
        @media (max-width: 575px) {
          font-size: 16px;
          line-height: 22px;

          @media (max-width: 480px) {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }

      p {
        @media (max-width: 480px) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      @media (max-width: 480px) {
        padding-left: 10px;
        width: calc(100% - 40px);
      }
    }

    .user_roll {
      li {
        padding-right: 15px;
      }

      @media (max-width: 767px) {
        li.btn_delete {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
          padding-right: 0;

          &.btn_edit {
            right: 30px;
          }
        }
      }
    }
  }
}

.roll_permission_inner {
  ul.roll_checkbox_wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      flex-grow: 1;
      padding: 0 12px;

      &:last-child {
        // margin-left: auto;
        // text-align: right;
        @media (max-width: 767px) {
          // display: none;
        }
      }

      @media (max-width: 1500px) {
        padding: 0 7px;

        // flex-grow: 0;
        @media (max-width: 767px) {
          width: 25%;

          @media (max-width: 575px) {
            width: 33.33%;

            @media (max-width: 400px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

.form_process_wrapper {
  .ant-form-item .ant-form-item-row .ant-form-item-control .ant-input {
    padding: 12px;
  }

  .ant-form-item .ant-form-item-row .ant-form-item-label label {
    font-size: 14px;
    color: $textLight;

    span {
      b {
        color: #ff4d4f;
        font-size: 12px;
        margin-left: 1px;
      }
    }
  }
}

.form_process_wrapper {
  padding-left: 34px;
  position: relative;

  .form_info_wrapper {
    position: relative;
    h3 {
      position: relative;
      display: inline-block;
      .validation_start {
        position: absolute;
        right: -10px;
        top: 0;
        font-size: 12px;
        color: #ff4d4f;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: -28px;
      top: 5px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #c9d5df;
      box-shadow: 0 0 0 6px rgba(201, 213, 223, 0.5);
      z-index: 2;
      transition: background 2s ease;
    }

    &:after {
      content: '';
      position: absolute;
      left: -22px;
      top: 10px;
      width: 1px;
      height: calc(100% - 10px);
      background: $textLight;
      z-index: 1;
    }

    &.filled {
      &:before {
        @include themify($themes) {
          background: themed('primary');
        }

        box-shadow: 0 0 0 6px rgba(211, 180, 116, 0.5);
      }

      &:after {
        @include themify($themes) {
          background: themed('primary');
        }
      }
    }
  }
}

.ant-upload-wrapper .ant-upload {
  width: 100%;
}

.photo_upload_inner .row {
  @media (max-width: 1700px) {
    flex-direction: column;

    @media (max-width: 1400px) {
      flex-direction: row;
    }
  }
}

// lead management page design start
.lead_managment_wrap {
  .page_inner_content {
    @include themify($themes) {
      background: themed('bgBox');
    }
  }
}

.lead_managment_wrap
  .ant-form-item
  .ant-form-item-row
  .ant-form-item-control
  .ant-input,
.ant-form-item .ant-picker,
.ant-select-selector {
  @include themify($themes) {
    background: themed('bgWhite') !important;
    // border-color: themed('bgBoxBorderColor') !important;
  }
}

.lead_managment_top {
  padding: 70px 50px;
  background-image: url(../../Img/gradiant_bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;
  margin-bottom: 25px;
  position: relative;

  p {
    @include themify($themes) {
      color: themed('textDark');
    }
  }

  .lead_managment_img_wrap {
    position: relative;
    max-width: 360px;
    text-align: center;
    position: absolute;
    right: 75px;
    width: 100%;
    top: -40px;

    .left_ladies {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .right_ladies {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .gif_img {
      background-image: url(../../Img/doc_gif.gif);
      background-size: 100%;
      background-repeat: no-repeat;
      display: inline-block;
      width: 100%;
      height: 250px;
    }
  }
}

.plus_btn {
  padding: 8px 10px !important;
  border-radius: 10px;

  svg {
    height: 22px;
    width: 22px;
  }
}

.bgbox_wrap {
  padding: 14px;
  border-radius: 12px;
  position: relative;
  @include themify($themes) {
    border: 1px solid themed('bgBoxBorderColor');
    background: themed('bgDarkBox');
    @media (max-width: 575px) {
      background: themed('bgBox');
    }
  }

  .form_info_wrapper .bgbox_wrap:last-child {
    margin-bottom: 0 !important;
  }

  .ant-form-item {
    .ant-picker {
      background: transparent;
    }
  }
  .delete_btn {
    position: absolute;
    right: 10px;
    top: 0;
    color: #d3b474;
    &:hover {
      color: #d3b474 !important;
    }
  }
}

.bg_box_list_wrap .bgbox_wrap:last-child {
  margin-bottom: 0 !important;
}

.lead_managment_wrap {
  display: none;
  &.show {
    display: block;
  }
}

// lead management page design end

.disabledInput {
  opacity: 0.6;
  pointer-events: none;
}

.location_select {
  .custom_select {
    .ant-row {
      align-items: center;
    }
  }
}

.location_header.custom_select {
  .ant-select {
    .ant-select-selector {
      padding: 0px 16px;
      border-radius: 6px;
      width: 145px;
    }

    .ant-select-arrow:after {
      left: 9px;
      top: -11px;
    }
  }
}

.location_select {
  h5 {
    width: 100px;
    margin: 0;
  }

  .custom_select {
    width: calc(100% - 100px);
  }
}

.model_delete_content {
  padding: 30px 20px 10px 20px;
  .delete_img {
    text-align: center;
    .anticon-close-circle {
      color: #ff4d4f;
      font-size: 50px;
    }
  }
}

.action_wrap ul li {
  margin-right: 10px;
}
.action_wrap ul {
  width: 60px;
}

.page_main_content.all_lead_wrap {
  .page_inner_content {
    overflow: hidden;
  }
}

.page_header_right ul li .custom_select .ant-select {
}

// responsive css start

@media (max-width: 1681px) {
  .page_main_content {
    padding: 20px;
  }
  .page_main_content.lead_managment_wrap .page_inner_content {
    padding: 15px;
  }
  .form_process_wrapper {
    padding-left: 30px;
  }
  .lead_managment_wrap .row > * {
    padding-left: 8px;
    padding-right: 8px;
  }
  .lead_managment_wrap .row {
    margin-right: -8px;
    margin-left: -8px;
  }
  .action_wrap ul li img {
    width: 17px;
  }
  .page_header {
    padding: 0 0 15px;
  }
}

@media (max-width: 1441px) {
  .form_process_wrapper
    .ant-form-item
    .ant-form-item-row
    .ant-form-item-control
    .ant-input {
    padding: 10px 12px;
  }
  .lead_managment_top h2 {
    font-size: 22px;
  }
  .lead_managment_top .lead_managment_img_wrap .left_ladies {
    bottom: 16px;
    width: 130px;
  }
  .lead_managment_top .lead_managment_img_wrap .right_ladies {
    bottom: 16px;
    width: 80px;
  }
  .lead_managment_top .lead_managment_img_wrap {
    max-width: 330px;
    top: -25px;
  }
  .page_main_content .page_inner_content {
    padding: 15px;
  }
}

@media (max-width: 1279px) {
  .main_wrapper {
    &.sidebar_collapse {
      .inner_wrapper {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          z-index: 99;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .all_lead_wrap .page_header_right ul li .custom_select {
    min-width: auto;
  }

  .all_lead_wrap .page_header_right ul {
    flex-wrap: wrap;
    gap: 10px 0;
    justify-content: space-between;
  }

  .lead_managment_top {
    padding: 70px 20px;
  }
  .lead_managment_top .lead_managment_img_wrap {
    right: 25px;
  }
  .page_main_content {
    padding: 20px 20px 20px;
    height: calc(100% - 90px);
  }
  .page_main_content.all_lead_wrap .page_inner_content {
    overflow: auto;
  }
  .page_header_right ul li .custom_select .ant-select {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .plus_mobile_wrap .btn_primary {
    min-width: auto;
    padding: 8px;
  }

  .btn_border,
  .btn_primary,
  .btn_gray {
    min-width: 80px;
  }

  .lead_managment_top {
    padding: 30px 20px 0 20px;
    text-align: center;
  }

  .lead_managment_top .lead_managment_img_wrap {
    position: relative;
    margin: 0 auto;
    top: 15px;
    right: 0;
  }
}

@media (max-width: 575px) {
  .search_wrap {
    width: 100%;
  }

  .page_main_content {
    padding: 20px 20px 20px;
    height: calc(100% - 92px);
  }

  .lead_managment_main_wrap .page_inner_content {
    padding: 0;
    border: 0 !important;
    border-radius: 0;
    background: transparent !important;
  }
  .form_process_wrapper {
    padding-left: 35px;
  }
  .page_main_content.lead_managment_wrap .page_inner_content {
    padding: 0;
  }
  .form_process_wrapper
    .ant-form-item
    .ant-form-item-row
    .ant-form-item-label
    label {
    font-size: 13px;
  }
}

@media (max-width: 481px) {
  header {
    padding: 20px 15px;
  }

  .search_bar_mobile {
    padding: 15px;
  }

  .page_main_content {
    padding: 20px 15px 15px;
    height: calc(100% - 80px);
  }

  .form_process_wrapper {
    padding-left: 25px;
  }

  .form_process_wrapper .form_info_wrapper:before {
    left: -20px;
    top: 7px;
    width: 10px;
    height: 10px;
  }

  .form_process_wrapper .form_info_wrapper:after {
    left: -15px;
    top: 10px;
    height: calc(100% - 6px);
  }

  .form_process_wrapper .form_info_wrapper:before {
    box-shadow: 0 0 0 5px rgba(201, 213, 223, 0.5);
  }

  .form_process_wrapper .form_info_wrapper.filled:before {
    box-shadow: 0 0 0 5px rgba(211, 180, 116, 0.5);
  }

  .ant-tabs
    .ant-tabs-nav
    .ant-tabs-nav-wrap
    .ant-tabs-nav-list
    .ant-tabs-tab
    .ant-tabs-tab-btn {
    font-size: 16px;
  }

  .ant-form-item-control
    .ant-checkbox-wrapper
    .ant-checkbox
    .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .btn_border,
  .btn_primary,
  .btn_gray {
    min-width: auto;
    padding: 8px 15px;
    font-size: 12px;
  }

  .lead_managment_top .lead_managment_img_wrap {
    max-width: 250px;
    margin: -30px auto 0;
  }
  .lead_managment_top .lead_managment_img_wrap .left_ladies {
    width: 100px;
  }
  .lead_managment_top .lead_managment_img_wrap .right_ladies {
    width: 80px;
  }
  .lead_managment_top .lead_managment_img_wrap .gif_img {
    height: 195px;
  }
  .lead_managment_top h2,
  .lead_managment_top .h2 {
    font-size: 20px;
  }
  .font_18 {
    font-size: 14px;
  }
  .plus_btn svg {
    height: 18px;
    width: 18px;
  }
  .plus_btn {
    padding: 8px !important;
  }
  .action_wrap ul li {
    margin-right: 5px;
  }
  .page_header {
    padding: 0 0 10px;
  }
}

@media (max-width: 400px) {
  .page_main_content .page_inner_content {
    padding: 15px;
  }
  .lead_managment_main_wrap.page_main_content .page_inner_content {
    padding: 0;
  }
  .user_main_wrap .user_box .user_img {
    width: 35px;
    height: 35px;
  }
  .user_main_wrap .user_box .user_text {
    padding-left: 5px;
    width: calc(100% - 35px);
  }
  .user_main_wrap .user_box .user_img .user_name_letter {
    font-size: 18px;
  }
  .page_main_content .page_inner_content {
    padding: 15px;
  }
}
