header {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 15px 30px;

  .hedaer_left {
    ul {
      display: flex;
      align-items: center;

      li {
        .toggle_btn {
          border: 0;
          padding: 0;
          margin-right: 30px;
          background: transparent !important;

          @include themify($themes) {
            filter: themed('brightnessBW');
          }
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }
  }

  .right_header {
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      li {
        padding: 0 15px;

        &:last-child {
          padding-right: 0;
          padding-left: 0;
        }

        .btn_transparent {
          position: relative;

          &.btn_notification {
            img {
              @include themify($themes) {
                filter: themed('brightnessBW');
              }
            }
          }

          span {
            width: 18px;
            height: 18px;
            background: #eb0505;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;

            @include themify($themes) {
              border: 2px solid themed('whiteBg') !important;
            }

            border-radius: 50%;
            font-size: 10px;
            font-weight: 500;
            position: absolute;
            right: -7px;
            top: 5px;
          }
        }
      }
    }
  }
}

.search_top {
  position: relative;

  .ant-select {
    width: 100%;

    .ant-select-selector {
      padding: 9px 30px;
      background: transparent;
      height: 50px;
      background: transparent;

      @include themify($themes) {
        border-color: themed('borderColor') !important;
        color: themed('textDark');
      }

      .ant-select-selection-search {
        .ant-select-selection-search-input {
          height: 50px;
          padding: 0 20px;
        }
      }

      // .ant-select-selection-placeholder {
      //   @include themify($themes) {
      //     color: themed("grayLight");
      //   }
      // }
    }

    &:hover,
    &.ant-select-focused {
      .ant-select-selector {
        border-color: #d3b474 !important;
        box-shadow: none;
      }
    }
  }

  .ant-input {
    padding: 12px 20px 12px 50px;
    background: url(../../Img/search.svg);
    background-repeat: no-repeat;
    background-position: left 14px center;
    border-radius: 10px;

    @include themify($themes) {
      border-color: themed('borderColor');
      color: themed('textDark');
    }

    &:focus,
    &:hover {
      box-shadow: none;

      @include themify($themes) {
        border-color: themed('primary');
      }
    }
  }

  .clearIcon {
    @include themify($themes) {
      color: themed('textDark') !important;
    }

    position: absolute;
    right: 17px;
    top: 6px;
  }
}

.language_dropdown {
  background: transparent;

  @include themify($themes) {
    border-color: themed('borderColor');
    color: themed('textDark');
  }

  img.down_arrow {
    @include themify($themes) {
      filter: themed('brightnessBW');
    }
  }
}

.ant-dropdown-trigger {
  &:hover,
  &:focus {
    @include themify($themes) {
      border-color: themed('primary') !important;
      color: themed('textDark') !important;
    }
  }
}

.user_dropdown {
  border: 0;
  height: auto;
  padding: 0;
  background: transparent !important;

  @include themify($themes) {
    color: themed('textDark') !important;
  }

  .user_img {
    width: 55px;
    height: 55px;
    position: relative;

    img {
      width: 55px;
      height: 55px;
      object-fit: cover;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 55px;
      height: 55px;
      background-repeat: no-repeat !important;
      background: url(../../Img/user-img-bg.svg);
      background-size: 55px;

      @media (prefers-color-scheme: dark) {
        background: url(../../Img/user-img-bg-dark.svg);
        background-size: 55px;
      }
    }
  }

  .down_arrow {
    @include themify($themes) {
      filter: themed('brightnessBW');
    }
  }
  &:focus {
    box-shadow: none !important;
  }
}

.right_header {
  .custom_select {
    .ant-select-selector {
      @include themify($themes) {
        border-color: themed('borderColor');
        color: themed('textDark');
      }

      &:hover,
      &:focus {
        @include themify($themes) {
          border-color: themed('primary') !important;
          color: themed('textDark') !important;
        }
      }
    }

    .ant-select-arrow {
      @include themify($themes) {
        color: themed('textDark');
      }
    }
  }
}

.theme-dark header {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

@media (max-width: 1681px) {
  header {
    padding: 15px 20px;
  }
  .search_top .ant-input {
    padding: 11px 20px 11px 40px;
    background-size: 18px;
  }
}

@media (max-width: 1441px) {
  .search_top .ant-input {
    padding: 10px 15px 9px 40px;
    background-position: left 14px center;
    background-size: 18px;
    font-size: 13px;
  }
}

@media (max-width: 1279px) {
  header .hedaer_left ul li .toggle_btn {
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  header {
    border-radius: 0px 0px 20px 20px;
    padding: 20px;
  }

  header .hedaer_left ul li .toggle_btn {
    margin-right: 10px;
  }

  header .right_header ul li {
    padding: 0 10px;
  }

  header .right_header ul li .btn_transparent {
    width: 30px;
  }

  .theme-dark header {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }

  .header_left_logo {
    margin-right: 20px;
  }

  .header_page_title h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .user_dropdown .user_img {
    width: 45px;
    height: 45px;
  }

  .user_dropdown .user_img:before {
    background-size: 45px !important;
  }

  .page_heading {
    h1 {
      text-align: center;
      margin: 0;
      font-size: 20px;
      line-height: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .search_top .ant-input {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .header_left_logo {
    display: none;
  }
  header .hedaer_left ul li .toggle_btn {
    margin-right: 0;
  }

  .page_heading {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 40px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 480px) {
  .page_heading img {
    max-width: 35px;
    margin-right: 5px;
  }
  .page_heading h1,
  .page_heading .h1 {
    font-size: 18px;
    line-height: 27px;
  }
}

@media (max-width: 400px) {
  header {
    padding: 20px 10px;
  }
  .page_heading img {
    max-width: 30px;
  }
  .page_heading h1,
  .page_heading .h1 {
    font-size: 16px;
    line-height: 24px;
  }
  .user_dropdown .user_img {
    width: 40px;
    height: 40px;
  }
  .user_dropdown .user_img:before {
    background-size: 40px !important;
  }
  .user_dropdown .user_img .user_inner_text {
    font-size: 20px !important;
  }
}
